<template>
  <div class="flowDetail" style="max-height:600px;overflow:auto">
    <div class="search">
      <el-form ref="form" :model="form" :inline="true">
        <el-form-item label="流量日期:">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.startDate"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.endDate"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item class="right">
          <el-button
            type="primary"
            class="themed-button"
            icon="el-icon-search"
            @click="search()"
            >搜索查询</el-button
          >
          <el-button
            type="primary"
            class="themed-button"
            icon="el-icon-back"
            @click="exportExcel()"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData">
        <el-table-column prop="iccidMark" label="sim卡号"> </el-table-column>

        <el-table-column prop="flowDate" label="流量日期"> </el-table-column>

        <el-table-column prop="usedFlow" label="已用流量"> </el-table-column>

        <el-table-column prop="usedVoice" label="已用语音流量"> </el-table-column>

        <el-table-column prop="createDate" label="查询时间"> </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  listFlowDetail,
  exportFlowDetail,
} from "@/api/cardManagement/cardBillDetail.js";
import loading from "@/utils/Loading.js";
import { messageBox } from "@/utils/common.js";

export default {
  props: ["iccidMark"],
  data() {
    return {
      form: {
        startDate: "",
        endDate: "",
      },
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this._listFlowDetail();
  },
  methods: {
    search() {
      this._listFlowDetail();
    },
    exportExcel() {
      loading.show();
      /* ../../card/exportFlowDetail.do?iccidMark=898608011919C1351506&amp;startDate=&amp;endDate=" */
      let params = {
        iccidMark: this.iccidMark,
        startDate: "",
        endDate: "",
      };
      exportFlowDetail(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    handleSizeChange() {
      this.pageSize = val;
      this._listFlowDetail();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this._listFlowDetail();
    },
    _listFlowDetail() {
      let params = Object.assign(this.form, {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        iccidMark: this.iccidMark,
      });
      listFlowDetail(params).then((res) => {
        this.currentPage = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
        this.tableData = res.rows.map((value, index, arr) => {
          return {
            iccidMark: value.iccidMark,
            flowDate: this.ftDate(value),
            usedFlow: value.usedFlow,
            usedVoice:value.usedVoice,
            createDate: this.ftDate(value),
          };
        });
      });
    },
    ftDate(rowData) {
      var date = new Date(rowData.flowDate);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    ftTime(rowData) {
      var date = new Date(rowData.flowDate);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
  },
};
</script>

<style>
.flowDetail .line,.flowDetail th, .flowDetail td{
  text-align: center;
}

</style>
